import styled from '@emotion/styled';
import React from 'react';
import { Content, continueWatchingData } from '../../apiService';
import { Card, Text, Icon, Box, Flex } from '../../styles';
import Ellipsis from '../../styles/Ellipsis';
import { getContentType, getEpisodeIdentifier, inspectFlags, timeToMinuets, usePlayerService } from '../../utils';
import PlayButton from '../../PlayButton';
import ProgressBar from '../../ProgressBar';
import ContinueWatchingActionPanel from './ContinueWatchingActionPanel';
import { CardContainer } from 'common/ContentCard';
import { useRouter } from 'next/router';

const ContinueWatchingCard = ({ content, deleteContent }:
  { content: continueWatchingData, deleteContent: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => void }) => {
  const { poster_image, type, flag, conditional_flag, id, seen_duration, duration, short_id } = content;
  const contentType = getContentType(type);
  const image = `${poster_image?.path}?w=300`;
  const flags = [conditional_flag, flag].join(', ');
  const { free, playable, hasAccess } = inspectFlags(flags);

  const [handleWatch, { loading }] = usePlayerService({ id, free, playable, hasAccess, ticket: { href: `tickets/${short_id}` } });
  const handleClick = () => {
    handleWatch('p');
  };

  return (
    <Container stack={contentType === 'tv_show'}>
      <Card orientation="vertical"
        onClick={handleClick}
        style={{
          backgroundImage: `url(${image})`,
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
        }}>
        <ButtonWrapper className='play-btn'>
          <PlayButton loading={loading} />
        </ButtonWrapper>
        <SeenDuration duration={duration} seen_duration={seen_duration} />
        <GradientX />
      </Card>
      <EpisodeInfo {...content} deleteContent={deleteContent} />
    </Container>
  );
};

// ------ //
// STYLES //
// ------ //

const SeenDuration = ({ duration,
  seen_duration }: {
    duration?: string,
    seen_duration?: string
  }) => {
  const seen = timeToMinuets(seen_duration);
  const total = timeToMinuets(duration);
  return <SeenDurationWrapper>
    {seen} از {total} دقیقه
  </SeenDurationWrapper>;
};

const EpisodeInfo = ({ episode, sub_title, duration, title, season, supper_title, short_id, slug, id,
  seen_duration, deleteContent }: EpisodeInfoProps) => {
  const episodeIdentifier = getEpisodeIdentifier({ season, episode });
  const href = `/contents/${short_id}/${slug}`;
  const router = useRouter();
  const subTitle = () => {
    if (episode?.toString() === '0') return sub_title;
    return episodeIdentifier || title;
  };

  return <EpisodeInfoWrapper style={{ flexGrow: 1 }} className='watching-card-footer'>
    <ProgressBar duration={duration} seen_duration={seen_duration} />
    <ContentBody onClick={() => router.push(href)}>
      {supper_title !== subTitle() && <Text as={Ellipsis} variant="body3" pt={1} weight='medium'>
        {supper_title !== subTitle() && supper_title}
      </Text>}
      <Flex justify={'space-between'} align={'center'} mt="auto" >
        <Text as={Ellipsis} variant="body3" pt={1} weight='regular'>
          {subTitle()}
        </Text>
        <ContinueWatchingActionPanel id={id} deleteContent={deleteContent} url={href} />
      </Flex>
    </ContentBody>
  </EpisodeInfoWrapper>;
};

export const SeenDurationWrapper = styled(Text)(({ theme }) => ({
  padding: theme.spacing(1),
  position: 'absolute',
  bottom: '0',
  width: '100%',
  zIndex: 2,
  textAlign: 'center',
}));

export const ButtonWrapper = styled.div(({ theme }) => ({
  padding: theme.spacing(1),
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50% , 200%)',
  [theme.breakpoints.down('lg')]: {
    transform: 'translate(-50% , -50%)',
  },
  [theme.breakpoints.down('sm')]: {
    [`${Icon}`]: {
      fontSize: '1rem',
    },
  },
}));

export const EpisodeInfoWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background[700][0],
  position: 'relative',
  minHeight: '5.125rem',
  padding: '8px 0',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
}));

const ContentBody = styled.div(() => ({
  padding: '0 10px',
  display: 'flex',
  flexDirection: 'column',
  height: '4.125rem',
}));

const Container = styled(CardContainer)<{ stack?: boolean }>(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    '& .progress-seek-bar': {
      background: theme.palette.accent[800][0],
    },
    '& .play-btn': {
      transform: 'translate(-50% , -50%)',
      transition: 'transform .5s ease',
    },
    '& .watching-card-footer': {
      background: theme.palette.background[600][0],
    },
  },
}));

export const GradientX = styled.div(() => ({
  background: 'linear-gradient(0deg, #292929 0%, rgba(41, 41, 41, 0.00) 100%)',
  position: 'absolute',
  bottom: -1,
  width: '100%',
  height: '31%',
  zIndex: 1,
}));

type EpisodeInfoProps = Pick<Content, 'episode' | 'id' | 'sub_title' | 'duration' | 'title' | 'season' | 'supper_title' | 'seen_duration' | 'short_id' | 'slug'> & { deleteContent: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => void };

export default ContinueWatchingCard;
