import React from 'react';

const IconCloseRound = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 15.0588C11.8985 15.0588 15.0588 11.8985 15.0588 8C15.0588 4.10152 11.8985 0.941176 8 0.941176C4.10152 0.941176 0.941176 4.10152 0.941176 8C0.941176 11.8985 4.10152 15.0588 8 15.0588Z" fill="white" />
      <path d="M4.99926 5.66967C4.81548 5.48589 4.81548 5.18793 4.99926 5.00416C5.18304 4.82038 5.481 4.82038 5.66477 5.00416L10.9889 10.3283C11.1726 10.512 11.1726 10.81 10.9889 10.9938C10.8051 11.1775 10.5071 11.1775 10.3234 10.9938L4.99926 5.66967Z" fill="white" />
      <path d="M5.66845 10.993C5.48467 11.1768 5.18671 11.1768 5.00294 10.993C4.81916 10.8093 4.81916 10.5113 5.00294 10.3275L10.327 5.00344C10.5108 4.81966 10.8088 4.81966 10.9925 5.00344C11.1763 5.18722 11.1763 5.48518 10.9925 5.66895L5.66845 10.993Z" fill="white" />
    </svg>
  );
};

export default IconCloseRound;
