
export default function ThreeDots() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="16" cy="10" rx="2" ry="2" fill="white" />
      <circle cx="16" cy="16" r="2" fill="white" />
      <circle cx="16" cy="22" r="2" fill="white" />
    </svg>
  );
}
