import React from 'react';
import { continueWatchingData } from '../../apiService/types';
import ContinueWatchingCard from './ContinueWatchingCard';
import Row, { ResponsiveCols } from '../../styles/Row';
import { SpacingProps } from '../../withStyleSystem/withSpacingStyles';

const ContinueWatchingList = ({ items, deleteContent, id, cols, ...spacingProps }:
  {
    items: continueWatchingData[],
    id?: string, cols?: ResponsiveCols, deleteContent: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => void
  } & SpacingProps) => {
  const colSize: ResponsiveCols = cols ? cols : { xs: 2, sm: 4, md: 6, lg: 8 };
  return (
    <Row id={id} cols={colSize} {...spacingProps}>
      {items.filter((item) => item?.short_id).map((item) => <ContinueWatchingCard
        key={item.short_id} content={item} deleteContent={deleteContent} />)}
    </Row>
  );
};

export default ContinueWatchingList;
