import { IconPlayClean } from './icons';
import { Icon, IconButton, Spinner } from './styles';

const PlayButton = ({ loading }: { loading: boolean }) => (
  <IconButton block variant="primaryA" round p={{ xs: 1, md: 2 }} isPlay>
    {loading ? <Spinner /> : <PlayIcon />}
  </IconButton>
);

export const PlayIcon = () => (
  <Icon cursor="pointer">
    <IconPlayClean />
  </Icon>
);

export default PlayButton;
