import React, { useState } from 'react';
import styled from '@emotion/styled';
import { __t } from 'common/i18n';
import { IconCloseRound, IconInfoEmptyDesign, ThreeDots } from 'common/icons';
import { Flex, Icon, Text } from 'common/styles';
import { useRouter } from 'next/router';

const ContinueWatchingActionPanel = ({ url, id, deleteContent }: {
  url: string,
  id: string, deleteContent: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => void
}) => {
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const router = useRouter();
  const handleBlur = () => {
    setOpenPanel(false);
  };
  return <div tabIndex={0} onBlur={() => handleBlur()}>
    <IconWrapper onClick={(e) => {
      e.stopPropagation();
      setOpenPanel((prev) => !prev);
    }} open={openPanel} >
      <ThreeDots />
    </IconWrapper >
    {openPanel &&
      <PanelWrapper >
        <Flex direction="column">
          <ActionWrapper onClick={() => router.push(url)} style={{
            borderTopRightRadius: '1rem',
            borderTopLeftRadius: '1rem',
          }}>
            <Icon ml={1} cursor="pointer">
              <IconInfoEmptyDesign />
            </Icon>
            <Text variant='body3'>
              {__t('common.more_info')}
            </Text>
          </ActionWrapper>
          <ActionWrapper onClick={(e) => deleteContent(e, id)} style={{
            borderBottomRightRadius: '1rem',
            borderBottomLeftRadius: '1rem',
          }}>
            <Icon ml={1} cursor="pointer">
              <IconCloseRound />
            </Icon>

            <Text variant='body3'>
              {__t('common.delete.item')}
            </Text>
          </ActionWrapper>
        </Flex>
      </PanelWrapper>}
  </div>;
};

export default ContinueWatchingActionPanel;

const IconWrapper = styled.div<{ open: boolean }>(({ theme, open }) => ({
  position: 'relative',
  cursor: 'pointer',
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: open ? theme.palette.background[400][0] : 'unset',
  '&:hover': {
    backgroundColor: theme.palette.background[400][0],
  },
  [theme.breakpoints.down('md')]: {
    width: '24px',
    height: '24px',
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

const ActionWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  padding: '1rem',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.background[300][0],
  },
  [theme.breakpoints.down('md')]: {
    padding: '12px',
  },
  '& span': {
    whiteSpace: 'nowrap',
  },
}));

const PanelWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  borderRadius: '1rem',
  backgroundColor: theme.palette.background[400][0],
  bottom: '35px',
  left: '45px',
  zIndex: 2,
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    bottom: '42px',
    left: '5px',
  },
}));
