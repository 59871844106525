import styled from '@emotion/styled';
import { timeToSeconds } from './utils';

const ProgressBar = ({ color, seen_duration, duration }: ProgressBarProps) => {
  const checkingLeftDuration = () => {
    const seen = timeToSeconds(seen_duration);
    const total = timeToSeconds(duration);
    if (seen && total) {
      if (seen > total) return 100;
      return Math.round((seen * 100 / total));
    }
    return 0;
  };

  // if (checkingLeftDuration() < 1) return <></>;
  return <ProgressBarWrapper >
    <Progress style={{ width: `${checkingLeftDuration()}%` }} color={color} className='progress-seek-bar' />
  </ProgressBarWrapper>;
};

export default ProgressBar;

export const ProgressBarWrapper = styled.div(({ theme }) => ({
  zIndex: 1,
  position: 'absolute',
  width: '100%',
  height: '0.25rem',
  background: theme.palette.background[300][0],
  top: 0,
  direction: 'ltr',
}));

export const Progress = styled.div<{ color?: string }>(({ theme, color }) => ({
  zIndex: 1,
  height: '0.25rem',
  background: color || theme.palette.foreground[100][0],
}));

export type ProgressBarProps = {
  color?: string,
  seen_duration?: string,
  duration?: string
}
