import { Content } from 'common/apiService';
import moment from 'moment-jalaali';

export function getVideoFile(content: Content, type: 'primary' | 'trailers' = 'primary') {
  const [file] = content?.video_files?.[type] || [];
  return file?.video_file_id;
}

export function timespanToSeconds(hms = '') {
  return hms.split(':').reduce((seconds, t, idx) => seconds + +t * [3600, 60, 1][idx], 0);
}

export function getTimerDuration(end?: string, now?: string) {
  if (!end) return;

  // const y = end.replace('T', ' ');
  // const z = now ? now?.replace('T', ' ') : null;

  const nowDate = now ? new Date(now).toUTCString() : new Date().toUTCString();
  const endDate = new Date(end).toUTCString();

  const nowInSeconds = new Date(nowDate).getTime() / 1000;
  const endInSeconds = new Date(endDate).getTime() / 1000;

  const timer = endInSeconds - nowInSeconds;
  if (timer > 0) return endInSeconds - nowInSeconds;
}

export const rialsToTomans = (price: number) => (price / 10).toLocaleString();

export const trimPrice = (price: string) => price.replace('تومان', '').trim();

export function debounce(func, delay) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const persianNumber2englishNumber = (s) => s.replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));

export const persianPhoneRegix = /^(0|98|0098|\+98)9\d{9}$/;
export const persianPhoneRegixPattern = new RegExp(persianPhoneRegix);
export const farsiNumberRegix = new RegExp(/[۰-۹]/g);

export function replacePhoneNumberPrefix(phoneNumber: number): string {
  const phoneNumberString: string = phoneNumber.toString();
  const regex = /^(?:\+98|98)/;
  if (regex.test(phoneNumberString)) {
    const modifiedPhoneNumberString: string = phoneNumberString.replace(regex, '');
    const modifiedPhoneNumberWithPrefix = `0${modifiedPhoneNumberString}`;
    return modifiedPhoneNumberWithPrefix;
  }
  return phoneNumberString;
}

export const timeToSeconds = (time: string | undefined) => {
  if (!time) return 0;
  const a = time.split(':');
  return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
};

export const timeToMinuets = (time: string | undefined) => {
  if (!time) return 0;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return Math.floor(timeToSeconds(time)! / 60);
};

export const convertToJalaali = (timestampDate: string | undefined) => {
  const date = timestampDate && moment(timestampDate, 'YYYY-MM-DDTHH:mm:ss');
  return {
    year: date ? date.format('jYYYY') : '',
    month: date ? date.format('jMM') : '',
    day: date ? date.format('jDD') : '',
  };
};
